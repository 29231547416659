import React from "react"
import { Link, graphql } from "gatsby"
import ArticleCard from '../components/article-card'
import PageTransition from 'gatsby-plugin-page-transitions'

import Layout from "../layout"
import PagButton from "../components/pagination"

//Chakra UI
import {
  Container,
  SimpleGrid,
  Heading,
  Text,
  Box,
  Image, 
  useBreakpointValue
} from '@chakra-ui/react'

//Icons
import { IconArrowLeft, IconArrowRight } from "@tabler/icons"
import Svg from "../images/statys-background-animation-small.svg"

const BlogIndex = ({ data, pageContext: { nextPagePath, previousPagePath }, }) => {
  const isMobile = useBreakpointValue({ base: true, xl: false })
  const posts = data.allWpPost.nodes

  //Automaticly display how many pages are there based on pageCount
  const totalCountOfPosts = posts.length
  const readingSettings = data.wp.readingSettings.postsPerPage

  function calculateNumberOfPages (readingSettings, totalCountOfPosts) {
    return totalCountOfPosts < readingSettings ? 1 : Math.ceil(totalCountOfPosts / readingSettings);
  }

  const total = calculateNumberOfPages(readingSettings, totalCountOfPosts)
  console.log(total)

  //array od numbers from total
  const pag = []
  for (var i = 0; i < total; i++){
    if (i != 0) {
      pag.push(i + 1)
      console.log(i)
    }
  }

  const style = {
    fontFamily: "Gotham Medium",
  }

  return (
    <Layout>
      <PageTransition>
      <Container maxW="container.xl" pt="140px">
        
        {!isMobile && (<Image src={Svg} style={{position: `absolute`, right: `0`, top: `72px`}} ></Image> )}
        
        <Box pb="40">
          <Heading textAlign="center" m="0px !important" fontSize="60px!important" color="blue.600" fontWeight="600" fontFamily="Gotham Light">Latest News</Heading>
          <Text textAlign="center" color="gray.600" pt="6"> Latest news from Statys Connect </Text>
        </Box>
            
        <SimpleGrid  columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: '12', lg: '9' }}>
          {posts.map((post) =>(
              <ArticleCard articles={post}></ArticleCard>
          ))}
        </SimpleGrid>
       
        {/* PAGINATION */}
        {/* <Flex p={50} w="full" alignItems="center" justifyContent="center">
          <Flex>
            {previousPagePath && (<Link to={previousPagePath}> <PagButton> <Icon as={IconArrowLeft} color="base.50" boxSize={4} /> </PagButton> </Link>)}
              <Link to={"/news/"}><PagButton>1</PagButton></Link>
            {pag.map((item) =>
              <Link to={"/news/" + item}><PagButton >{item}</PagButton></Link>
            )}
            {nextPagePath &&  (<Link to={nextPagePath}> <PagButton> <Icon as={IconArrowRight} color="base.50" boxSize={4}/> </PagButton> </Link>)}
            </Flex>
        </Flex> */}
        </Container>
        </PageTransition>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogNews($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "news"}}}}}
    ) {
      nodes {
        id
        link
        slug
        excerpt
        uri
        content
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          nodes {
            name
            slug
          }
        }
        author {
          node {
            name
          }
        }
         featuredImage {
          node {
            link
            srcSet
            sourceUrl
         }
        }
      }
    }
    wp {
      readingSettings {
        postsPerPage
      }
    }
  }
`
